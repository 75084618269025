import domReady from '@wordpress/dom-ready';

domReady(() => {
  const banners = document.querySelectorAll('.list__scroll-left');

  if (0 < banners.length) {
    banners.forEach((banner) => {
      const words = banner.querySelectorAll('.list__scroll__item');
      const repetitions = 12 / words.length;
      for (let i = 0; i < repetitions; i++) {
        words.forEach((word) => {
          const clone = document.createElement('p');
          clone.classList.add('list__scroll__item');
          clone.textContent = word.textContent;
          banner.appendChild(clone);
        });
      }
    });
  }
});
